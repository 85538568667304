import { graphql, PageProps } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import React from "react"

import Layout from "./layout"

import "../styles/article.scss"
import Seo from "./seo"

interface ArticlePageContent {
  featureImageSharp: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  id: string
  slug: string
  title: string
  excerpt: string
  feature_image: string
  url: string
  html: string
  published_at: string
  tags: {
    id: string
    slug: string
  }
  primary_tag: {
    id: string
    slug: string
  }
  primary_author: {
    name: string
  }
  reading_time: number
  meta_description: string
  defaultImage: FluidObject
}

interface PageData {
  allGhostPost: { edges: { node: ArticlePageContent }[] }
}
interface PageContext {
  defaultImage: FluidObject
}

const Article = ({
  data,
  location,
  pageContext: { defaultImage },
}: PageProps<PageData, PageContext>) => {
  console.log("data", data, JSON.stringify(data, null, 2))
  const post = data.allGhostPost.edges[0].node
  const image = post.featureImageSharp?.childImageSharp.fluid || defaultImage
  const { title, html, reading_time, primary_author, meta_description } = post
  return (
    <Layout location={location}>
      <Seo
        title={title}
        description={meta_description}
        author={primary_author.name}
      />
      <div className="Article">
        <h1>{title}</h1>
        <p className="read-time">{reading_time} minute read</p>
        {image && <Img fluid={image} className="title-image" />}
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
    </Layout>
  )
}

export default Article

export const postQuery = graphql`
  query($id: String!) {
    allGhostPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          featureImageSharp {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          id
          slug
          title
          excerpt
          feature_image
          url
          html
          published_at
          tags {
            id
            slug
          }
          primary_tag {
            id
            slug
          }
          reading_time
          primary_author {
            name
          }
          meta_description
        }
      }
    }
  }
`
